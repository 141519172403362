
/deep/.el-step__description{
    padding-top: 20px;
}
/deep/.el-step__head.is-finish{
    /*background: #1122D8;*/
    /*color: #ffffff;*/
    /*border-color: #1122D8;*/
}
/deep/.el-step__icon{
    width: 48px;
    height: 48px;
    font-size: 24px;
}
/deep/.el-step__line{
    margin-top: 12px;
}


