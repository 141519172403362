

.createStep{
    height: 100%;
    /*padding: 30px;*/
    display: flex;
    flex-direction: column;
    /deep/.el-scrollbar__wrap{
        overflow-x: hidden;
        .el-scrollbar__view{
            padding: 30px;
        }
    }
    .createStepProgress{
        margin: 30px auto;
        padding-bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        .step-num{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            span:nth-child(1){
                display: inline-block;
                width: 48px;
                height: 48px;
                font-size: 24px;
                color: #FFFFFF;
                background: #DCDCDC;
                border-radius: 50%;
                text-align: center;
                line-height: 48px;
            }
            .active{
                background: #1122D8!important;
            }
            span:nth-child(2){
                display: inline-block;
                width: 100px;
                text-align: center;
                top: 68px;
                /*left: 30%;*/
                position: absolute;
                font-size: 16px;
                color: #333333;

            }
        }
        .step-wire{
            height: 14px;
            margin: 0 10px;
        }
    }
    .createStepBottom{
        margin-top: 100px;
        display: flex;
        justify-content: center;
        .last-step{
            width: 100px;
            background: #ffffff;
            color: #1122D8;
            border-color:#1122D8 ;
        }
        .next-step{
            width: 100px;
            background: #1122D8;
            color: #ffffff;
            border-color: #1122D8;
            margin-left: 20px;
        }
    }
    .createStepForm{
        height: 100%;
    }
}
